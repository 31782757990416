import {
  AmountLockBehaviour,
  Breakdown,
  BreakdownUpdatePayload,
  ReservationExtendedBreakdown,
  UnitPrice,
} from 'types/Api/Reservation';

import { BasePriceType, ReservationPriceValueDto } from '@ac/library-api';

import { ComparisonType, DateTimeManager } from '@gss/services/DateTimeManager';

function getBasePrice(price?: UnitPrice): ReservationPriceValueDto | undefined {
  if (!price?.currency) return undefined;

  const basePrice: ReservationPriceValueDto = {
    amount: 0,
    currency: price.currency,
  };

  if (price.basePriceType === BasePriceType.Gross) {
    basePrice.amount = price.gross;
  } else {
    basePrice.amount = price.net;
  }

  return basePrice;
}

export function mapReservationExtendedBreakdownToUpdateBreakdownPayload(
  reservationExtendedBreakdown: ReservationExtendedBreakdown[],
  breakdown: Breakdown[]
): BreakdownUpdatePayload[] {
  return reservationExtendedBreakdown.map((item, index) => {
    const { ratePricingDetails } = item;

    const breakdownItem = breakdown.find((element) => {
      return (
        DateTimeManager.comparison(item.fromDate, element.fromDate, {
          type: ComparisonType.isSameOrAfter,
        }) &&
        DateTimeManager.comparison(item.toDate, element.toDate, {
          type: ComparisonType.isSameOrBefore,
        })
      );
    });

    const amountLock = {
      behaviour: item.amountLock?.lockedReason
        ? AmountLockBehaviour.Locked
        : AmountLockBehaviour.Undefined,
      lockedReason: item.amountLock?.lockedReason,
    };

    return {
      fromDate: item.fromDate,
      toDate: item.toDate,
      guests: item.guests,
      ratePricingDetails: {
        ratePlanId: ratePricingDetails.ratePlanId,
        roomTypeToChargeId: ratePricingDetails.roomTypeToChargeId,
        promotionId: ratePricingDetails.promotionId,
        memberRateId: ratePricingDetails.memberRateId,
      },
      marketSegmentId: item.marketSegmentId,
      rateBasePricePerDay: getBasePrice(breakdownItem?.ratePricePerDay),
      basePricePerDay: getBasePrice(breakdownItem?.pricePerDay),
      discount: item.discount,
      type: item.type,
      amountLock,
    };
  });
}

export default {
  mapReservationExtendedBreakdownToUpdateBreakdownPayload,
};
