import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  Body,
  Footer,
  FormHeader,
  FormSelect,
  FormTextField,
  View,
} from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import {
  Field,
  Form,
  initialize,
  InjectedFormProps,
  reduxForm,
  submit,
} from 'redux-form';
import { fetchCheckInData } from 'store/actions';
import { activeAuthorizations } from 'store/cashiering/authorization/selectors';
import { getReservationOutstandingDeposit } from 'store/cashiering/reservationOutstandingDeposit/selectors';
import {
  getActiveBillingInstructions,
  getAddons,
  getFolios,
  getPreAuthorizationAmount,
} from 'store/cashiering/selectors';
import {
  chooseProfile,
  createIndividual,
  fetchCreateIndividualStatus,
  fetchProfile,
  updateProfileDetails,
} from 'store/profile/actions';
import {
  getIsIndividualCreated,
  getNewIndividualId,
  getProfile,
} from 'store/profile/selectors';
import { getLocalPropertyDateTime } from 'store/propertyManagement/selectors';
import {
  fetchBreakdown,
  fetchDetailedPricing,
  fetchReservationExtended,
  updateReservationProfile,
} from 'store/reservation/actions';
import {
  getReservation,
  getReservationBreakdown,
  getReservationExtendedBreakdown,
  getReservationId,
  getReservationVersion,
  isMultiRoomReservation,
} from 'store/reservation/selectors';
import { getErrors } from 'store/selectors';
import { PurchaseItem } from 'types/Api/Availability';
import {
  BillingInstruction,
  CreditCardAuthorizationDetails,
  Folio,
} from 'types/Api/Cashiering';
import { Profile } from 'types/Api/Profile';
import {
  Breakdown,
  ReservationExtendedBreakdown,
  ReservationView,
} from 'types/Api/Reservation';
import { ApiError, Money } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';
import { getLanguageCode } from 'utils/Configurator';
import { mapReservationExtendedBreakdownToUpdateBreakdownPayload } from 'utils/Shared';
import { required } from 'utils/Validator';
import { configureCheckInRouting } from 'views/CheckInAuth/routing';

import { SelectOption } from '@ac/kiosk-components';

import { Header } from '@gss/components/layout';
import { DEFAULT_APP_LANGUAGE } from '@gss/configs/constants';
import { getExternalSettings } from '@gss/store/settings/selectors';
import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import { Grid } from '@material-ui/core';

const { CHECK_IN_FAILED_MESSAGE } = Configurator.getTranslationCodes();

interface CheckInNameDetailsProps
  extends RouteComponentProps,
    InjectedFormProps,
    WithTranslation {
  newIndividualId: string;
  reservation: ReservationView;
  reservationId: string;
  reservationVersion: number;
  reservationExtendedBreakdown: ReservationExtendedBreakdown[];
  isIndividualCreated: boolean;
  profile: Profile;
  errors: ApiError[];
  addons: PurchaseItem[];
  activeAuthorizations: CreditCardAuthorizationDetails[];
  outstandingDeposit: Money;
  localPropertyDateTime: string;
  preAuthorizationAmount: number;
  billingInstructions: BillingInstruction[];
  folios: Folio[];
  isMultiRoomReservation: boolean;
  externalSettings: ReturnType<typeof getExternalSettings>;
  reservationBreakdown: Breakdown[];
  updateReservationProfile: typeof updateReservationProfile;
  updateProfileDetails: typeof updateProfileDetails;
  createIndividual: typeof createIndividual;
  fetchProfile: typeof fetchProfile;
  chooseProfile: typeof chooseProfile;
  fetchCreateIndividualStatus: typeof fetchCreateIndividualStatus;
  fetchReservationExtended: typeof fetchReservationExtended;
  fetchDetailedPricing: typeof fetchDetailedPricing;
  submit: typeof submit;
  onInitialize: typeof initialize;
  fetchCheckInData: typeof fetchCheckInData;
  fetchBreakdown: typeof fetchBreakdown;
}

interface CheckInNameDetailsState {
  error?: ApiError;
  titleOptions: Array<{
    value: string;
    title: string;
  }>;
}

class CheckInNameDetails extends PureComponent<
  CheckInNameDetailsProps,
  CheckInNameDetailsState
> {
  public static defaultProps = {};

  state: CheckInNameDetailsState = {
    titleOptions: [],
    error: undefined,
  };

  componentDidMount() {
    const { isIndividualCreated } = this.props;
    this.prepareTitleOptions();
    isIndividualCreated && this.reinitializeForm();
  }

  componentDidUpdate(prevProps: CheckInNameDetailsProps) {
    const { t } = this.props;
    if (prevProps.t !== t) {
      this.prepareTitleOptions();
    }
  }

  public render() {
    const { t, invalid, submitting, handleSubmit, errors } = this.props;
    const { titleOptions, error } = this.state;
    const isSubmitDisabled = invalid || submitting;

    return (
      <View
        idle={{ type: 'modal' }}
        modal={{
          values: (error && [error]) || errors,
          customErrorCode: CHECK_IN_FAILED_MESSAGE,
          onClick: this.clearLocalErrors,
          shouldClearErrors: true,
        }}
      >
        <Header title={`${t('CHECK_IN')} - ${t('NAME_DETAILS')}`} />
        <Body>
          <FormHeader
            title={t('NAME_DETAILS_TITLE')}
            subtitle={t('NAME_DETAILS_SUBTITLE')}
          />
          <Form onSubmit={handleSubmit(this.onSubmit)} autoComplete="off">
            <Grid container spacing={3}>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="title"
                    component={FormSelect}
                    options={titleOptions}
                    label={t('TITLE_OPTIONAL')}
                    empty
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="firstName"
                    component={FormTextField}
                    validate={[required]}
                    label={t('FIRST_NAME')}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field
                    name="lastName"
                    component={FormTextField}
                    validate={[required]}
                    label={t('LAST_NAME')}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Body>
        <Footer
          hasCancelButton
          hasContinueButton
          routeName={t('CHECK_IN')}
          onContinue={this.submit}
          isContinueDisabled={isSubmitDisabled}
        />
      </View>
    );
  }

  private submit = () => {
    const { submit } = this.props;
    submit(ids.CHECKIN_NAME_DETAILS);
  };

  private onSubmit = async (values: any) => {
    const {
      history,
      isIndividualCreated,
      reservationId,
      dirty: isDirty,
      fetchCheckInData,
      fetchProfile,
      chooseProfile,
      fetchReservationExtended,
      fetchDetailedPricing,
    } = this.props;

    try {
      if (isDirty) {
        if (isIndividualCreated) {
          await this.updatePreviouslyCreatedProfile(values);
        } else {
          await this.createAndAssignNewProfile(values);

          const { newIndividualId } = this.props;

          await Promise.all([
            fetchProfile(newIndividualId),
            fetchReservationExtended(reservationId),
            fetchDetailedPricing(reservationId),
          ]);

          chooseProfile(newIndividualId);
          await fetchCheckInData();
          this.configureRouting();
        }
      }

      history.push(Router.nextStepURL);
    } catch (error) {
      this.setState({ error });
    }
  };

  private createAndAssignNewProfile = async (values: any) => {
    const {
      reservationId,
      createIndividual,
      updateReservationProfile,
      fetchReservationExtended,
      fetchCreateIndividualStatus,
      fetchBreakdown,
    } = this.props;

    await Promise.all([
      fetchReservationExtended(reservationId),
      fetchBreakdown(reservationId),
    ]);

    const newProfileData = {
      details: {
        firstName: values.firstName,
        lastName: values.lastName,
        titleCode: values.title,
      },
      typeCode: Configurator.getProfileTypeCode(
        Configurator.profileRoles.INDIVIDUAL
      ),
    };

    await createIndividual(newProfileData);

    const {
      newIndividualId: profileId,
      reservationId: id,
      reservationVersion: version,
      reservationExtendedBreakdown,
      reservation: {
        linkedCompany,
        linkedTravelAgent,
        guestContactProfiles,
        guaranteeTypeId,
      },
      reservationBreakdown,
    } = this.props;

    await fetchCreateIndividualStatus(profileId);
    await updateReservationProfile(
      { id, version },
      {
        breakdown: mapReservationExtendedBreakdownToUpdateBreakdownPayload(
          reservationExtendedBreakdown,
          reservationBreakdown
        ),
        profileId,
        linkedCompany,
        linkedTravelAgent,
        guestContactProfiles,
        guaranteeTypeId,
      }
    );
  };

  private updatePreviouslyCreatedProfile = async (values: any) => {
    const {
      newIndividualId: profileId,
      updateProfileDetails,
      fetchProfile,
    } = this.props;

    await fetchProfile(profileId);
    const {
      profile: { version },
    } = this.props;

    const titleId = Configurator.titles.find(
      ({ code }) => code === values.title
    )?.id;

    const updatedProfileDetails = {
      firstName: values.firstName,
      lastName: values.lastName,
      titleId,
    };

    await updateProfileDetails(profileId, version, updatedProfileDetails);
  };

  private clearLocalErrors = () => {
    this.setState({ error: undefined });
  };

  private mapTitleOptions = (languageCode: string): SelectOption[] => {
    return Configurator.titles
      .map((title) => ({
        value: title.code,
        title: title.description?.find(
          (item) =>
            item.languageCode?.toUpperCase() === languageCode?.toUpperCase()
        )?.content,
      }))
      .filter(
        (titleOption) => titleOption.value && titleOption.title
      ) as SelectOption[];
  };

  private prepareTitleOptions = () => {
    const { i18n, externalSettings } = this.props;

    const defaultLanguageCode = (
      externalSettings?.LANGUAGE.languageCode || DEFAULT_APP_LANGUAGE
    )?.toUpperCase();
    const languageCode = i18n.language.toUpperCase();

    let titleOptions = this.mapTitleOptions(languageCode);

    if (titleOptions.some((titleOption) => !titleOption.title)) {
      titleOptions = this.mapTitleOptions(defaultLanguageCode);
    }

    if (titleOptions.some((titleOption) => !titleOption.title)) {
      const defaultLanguageCode = getLanguageCode();
      titleOptions = this.mapTitleOptions(defaultLanguageCode);
    }

    if (titleOptions.some((titleOption) => !titleOption.title)) {
      titleOptions = [];
    }

    this.setState({
      titleOptions: titleOptions as Array<{ value: string; title: string }>,
    });
  };

  private reinitializeForm = () => {
    const {
      profile: {
        details: { firstName, lastName, titleCode },
      },
      onInitialize,
    } = this.props;

    const title = Configurator.titles.find(
      ({ code }) => code === titleCode
    )?.code;

    onInitialize(ids.CHECKIN_NAME_DETAILS, {
      firstName,
      lastName,
      title: title || '',
    });
  };

  private configureRouting() {
    const {
      addons,
      activeAuthorizations,
      outstandingDeposit,
      localPropertyDateTime,
      preAuthorizationAmount,
      billingInstructions,
      folios,
      isMultiRoomReservation,
    } = this.props;

    configureCheckInRouting(
      addons,
      activeAuthorizations,
      outstandingDeposit,
      localPropertyDateTime,
      preAuthorizationAmount,
      billingInstructions,
      folios,
      isMultiRoomReservation
    );
  }
}

const mapStateToProps = (state: Store, props: CheckInNameDetailsProps) => ({
  profile: getProfile(state),
  newIndividualId: getNewIndividualId(state),
  reservation: getReservation(state),
  reservationId: getReservationId(state),
  reservationVersion: getReservationVersion(state),
  reservationExtendedBreakdown: getReservationExtendedBreakdown(state),
  isIndividualCreated: getIsIndividualCreated(state),
  errors: getErrors(state),
  isMultiRoomReservation: isMultiRoomReservation(state),
  folios: getFolios(state),
  billingInstructions: getActiveBillingInstructions(state),
  preAuthorizationAmount: getPreAuthorizationAmount(state),
  localPropertyDateTime: getLocalPropertyDateTime(state),
  outstandingDeposit: getReservationOutstandingDeposit(state),
  activeAuthorizations: activeAuthorizations(state),
  addons: getAddons(state, props),
  externalSettings: getExternalSettings(state),
  reservationBreakdown: getReservationBreakdown(state),
});

const mapDispatchToProps = {
  submit,
  createIndividual,
  updateReservationProfile,
  updateProfileDetails,
  fetchCreateIndividualStatus,
  fetchReservationExtended,
  fetchDetailedPricing,
  fetchProfile,
  chooseProfile,
  fetchCheckInData,
  onInitialize: initialize,
  fetchBreakdown,
};

export default compose(
  withRouter,
  withTranslation(),
  reduxForm({ form: ids.CHECKIN_NAME_DETAILS }),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInNameDetails) as () => JSX.Element;
