import Big from 'big.js';
import {
  PurchaseElementData,
  Reservation,
  ReservationView,
  UpdatePurchaseElementData,
} from 'types/Api/Reservation';

import {
  ReservationAddOnPurchaseElementDetailedPricingDto,
  ReservationRoomStayDetailedPricingDto,
} from '@ac/library-api';

export const getOperationId = (location: string) => {
  const result = location.split('/');

  return result[result.length - 1];
};

export const parsePreAddedElements = (
  preAddedElements: ReservationAddOnPurchaseElementDetailedPricingDto[]
): PurchaseElementData[] => {
  return preAddedElements.map((element) => {
    const purchaseElementPriceDetails =
      element.addOnPurchaseElementItemsBreakdowns[0]
        .purchaseElementPriceDetails;
    const quantity =
      purchaseElementPriceDetails?.flatPrice?.quantity ??
      purchaseElementPriceDetails?.occupancyPrices?.quantity ??
      0;

    return {
      quantity,
      purchaseElementId: element.purchaseElementId,
      id: element.id,
      dateAnchor: element.dateAnchor,
      breakdown: element.addOnPurchaseElementItemsBreakdowns.map(
        (breakdown) => ({
          date: breakdown.date,
          transactionCodeId:
            breakdown.purchaseElementPriceDetails.transactionCodeId,
          chargeId: breakdown.purchaseElementPriceDetails.chargeId,
        })
      ),
    };
  });
};

export const parseLinkedPurchase = (
  breakdownElement: ReservationRoomStayDetailedPricingDto
): PurchaseElementData[] => {
  return (breakdownElement.linkedPurchaseElements || []).map(
    ({ id, purchaseElementPriceDetails }) => {
      const quantity =
        purchaseElementPriceDetails?.flatPrice?.quantity ??
        purchaseElementPriceDetails?.occupancyPrices?.quantity ??
        0;

      return {
        quantity,
        purchaseElementId: id,
        breakdown: [
          {
            fromDate: breakdownElement.startDate,
            toDate: breakdownElement.endDate,
          },
        ],
      };
    }
  );
};

export const parseElementsFromRate = (
  elementsFromRate: ReservationRoomStayDetailedPricingDto[] = []
): PurchaseElementData[] => {
  return elementsFromRate.reduce((prev: PurchaseElementData[], curr) => {
    const linkedPurchaseElem = parseLinkedPurchase(curr);

    linkedPurchaseElem.forEach((newElement) => {
      const equalPrevElement = prev.find(
        (previosElement) =>
          previosElement.purchaseElementId === newElement.purchaseElementId
      );

      if (equalPrevElement) {
        equalPrevElement.breakdown.push(...newElement.breakdown);
      } else {
        prev.push(newElement);
      }
    });

    return prev;
  }, []);
};

export const sumPrices = (
  items: ReservationAddOnPurchaseElementDetailedPricingDto[] = []
) => {
  return items
    .reduce((prev, { aggregatedAmount }) => {
      return prev.plus(aggregatedAmount.gross.amount);
    }, new Big(0))
    .toNumber();
};

export const parseReservationData = (data: Reservation | ReservationView) => {
  const {
    accountId,
    linkedTravelAgent,
    linkedCompany,
    guestContactProfiles,
    relations,
  } = data;

  return {
    accountId,
    reservations: [
      {
        ...data,
        ...(relations &&
          !Array.isArray(relations) && {
            sharingId: relations.shareId,
            segment: relations.segment,
          }),
      },
    ],
    linkedProfiles: {
      linkedTravelAgent,
      linkedCompany,
      guestContactProfiles,
    },
  };
};
