import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Body as BasicBody } from '@ac/kiosk-components';

import { BackgroundImagePosition } from '@gss/store/settings/interfaces/settings/imagesSettings';
import { getImages } from '@gss/store/settings/selectors';

interface BodyProps extends React.ComponentProps<typeof BasicBody> {
  hasBackgroundImage?: boolean;
}

export const Body = ({
  children,
  hasBackgroundImage = true,
  ...props
}: BodyProps): JSX.Element => {
  const images = useSelector(getImages);

  const backgroundImage = useMemo(() => {
    if (!hasBackgroundImage || !images?.VIEW_BACKGROUND_IMAGE) return;

    return images?.VIEW_BACKGROUND_IMAGE;
  }, [images?.VIEW_BACKGROUND_IMAGE, hasBackgroundImage]);

  const imagePosition = useMemo(() => {
    if (!backgroundImage) return;

    return images?.BACKGROUND_IMAGE_POSITION || BackgroundImagePosition.Tile;
  }, [images?.BACKGROUND_IMAGE_POSITION, backgroundImage]);

  return (
    <BasicBody
      backgroundImage={backgroundImage}
      backgroundImagePosition={imagePosition}
      {...props}
    >
      {children}
    </BasicBody>
  );
};
