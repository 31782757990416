import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { create } from 'jss';

import { getTheme } from '@LEGACY/store/ui/selectors';
import { StyleRenderer } from '@LEGACY/StyleRenderer';
import {
  createTheme,
  jssPreset,
  MuiThemeProvider,
  StylesProvider,
} from '@material-ui/core/styles';

const jss = create({
  Renderer: StyleRenderer,
  plugins: [...jssPreset().plugins],
});

const MuiProvider = ({ children }: PropsWithChildren<unknown>) => {
  const legacyTheme = useSelector(getTheme);

  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={createTheme(legacyTheme)}>
        {children}
      </MuiThemeProvider>
    </StylesProvider>
  );
};

export default MuiProvider;
