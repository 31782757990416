// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import css from 'css';
import { Renderer } from 'jss';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DomRenderer from 'jss/src/DomRenderer';

interface IGSSRenderer {
  sheet: {
    options: { link: string };
    rules: unknown[];
  };
  insertRules: (rules: unknown[]) => void;
  element: HTMLStyleElement;
}

type CssRule =
  | {
      selectors: string[];
      type: 'rule';
    }
  | {
      rules: Array<{ selectors: string[] }>;
      type: 'media';
    };

const scopeClass = '.kiosk-components-scope';

const scopeCss = (cssText: string): string => {
  const ast = css.parse(cssText);

  ast.stylesheet.rules.map((rule: CssRule) => {
    if (rule.type === 'rule') {
      rule.selectors = rule.selectors.map(
        (selector: string) => `${scopeClass} ${selector}`
      );
    } else if (rule.type === 'media') {
      rule.rules.map((rule) => {
        rule.selectors = rule.selectors.map(
          (selector: string) => `${scopeClass} ${selector}`
        );
      });
    }
  });

  return css.stringify(ast);
};

// Patch original renderer from jss package
class GSSRenderer extends DomRenderer {
  constructor(...args: unknown[]) {
    super(...args);
  }
  // mostly a copy of original method + adding scoped class to all selectors
  // before injecting to style element
  deploy() {
    const oThis = this as unknown as IGSSRenderer;
    const { sheet } = oThis;
    if (!sheet) return;
    if (sheet.options.link) {
      oThis.insertRules(sheet.rules);

      return;
    }
    const cssText = sheet.toString();

    oThis.element.textContent = `\n${scopeCss(cssText)}\n`;
  }
}

export const StyleRenderer = GSSRenderer as unknown as new () => Renderer;
