import { BackgroundImagePosition } from '@ac/kiosk-components';

import { SettingsCodeMap } from '../../utils';

export { BackgroundImagePosition };

export interface ImagesSettingsState {
  WELCOME_LOGO?: string;
  SCREEN_SAVER_LOGO?: string;
  WELCOME_BACKGROUND_IMAGE?: string;
  BACKGROUND_IMAGE_POSITION?: BackgroundImagePosition;
  CARD_PAYMENT_IMAGE1?: string;
  CARD_PAYMENT_IMAGE2?: string;
  VIEW_BACKGROUND_IMAGE?: string;
  KEYS_GENERATE_IMAGE?: string;
  PASSPORT_SCANNING_IMAGE?: string;
}

export const imagesCodesMap: SettingsCodeMap<ImagesSettingsState> = {
  WELCOME_LOGO: {
    key: 'WELCOMELOGO',
  },
  SCREEN_SAVER_LOGO: {
    key: 'SCREENSAVERLOGO',
  },
  WELCOME_BACKGROUND_IMAGE: {
    key: 'WELCOMEBACKGROUNDIMAGE',
  },
  BACKGROUND_IMAGE_POSITION: {
    key: 'BACKGROUNDIMAGEPOS',
  },
  CARD_PAYMENT_IMAGE1: {
    key: 'PREAUTHIMAGE1',
  },
  CARD_PAYMENT_IMAGE2: {
    key: 'PREAUTHIMAGE2',
  },
  VIEW_BACKGROUND_IMAGE: {
    key: 'BACKGROUNDIMAGE',
  },
  KEYS_GENERATE_IMAGE: {
    key: 'KEYGENERATEIMAGE',
  },
  PASSPORT_SCANNING_IMAGE: {
    key: 'PASSPORTSCANNINGIMAGE',
  },
};
