import { AxiosResponse } from 'axios';
import { api } from 'config';
import { getCheckInSummaryPdf } from 'store/checkInProcess/selectors';
import { savePhotos, uploadPdf } from 'store/files/actions';
import { CALL_API } from 'store/middleware/api';
import { getEmail } from 'store/profile/selectors';
import {
  getAssignRoomOperation,
  getPreAddedInventoryItems,
  getReservation,
  getReservationId,
  getReservationVersion,
  getSharingVersion,
  getSuggestedRoom,
} from 'store/reservation/selectors';
import { getDocumentPhotos } from 'store/selectors';
import { Dispatch, GetState } from 'store/utils/actions';
import {
  BreakdownUpdatePayload,
  ContactProfiles,
  CorrespondenceDefinitionByRole,
  CutKeyDataModel,
  LinkedContactProfile,
  UpdatePurchaseElementData,
  UpdatePurchaseFormData,
} from 'types/Api/Reservation';
import { Configurator } from 'utils';

import {
  ApiOperationDto,
  BaseApi,
  buildUrl,
  CorrespondenceFileExtensionType,
  EmptyInterface,
  extractIdFromLocationHeader,
  OperationStatus,
  ReservationLongRunningOperationResult,
  UpdateReservationInventoryItems,
  UpdateReservationOperationDto,
} from '@ac/library-api';
import {
  OperationsApi,
  ReservationApi,
} from '@ac/library-api/dist/api/v0/booking';
import { repeatableCall } from '@ac/library-utils/dist/utils';

import { getPropertyConfiguration } from '@gss/store/settings/selectors';
import { RoomError } from '@gss/utils/errors';
import { objectUrlToBlob } from '@gss/utils/files';

import { getConfirmedNewInventoryItems } from '../../views/CheckInAddons/store/selectors';

import { prepareUpdatePurchaseElementsPayload } from './utils/updatePurchaseElementsPayload';
import types from './types';
import { verifyIfReservationViewIsCheckedInStatus } from './utils';

export type PreparedPurchaseElementsUpdatePayload = {
  inventoryItems: UpdateReservationInventoryItems;
  purchaseElements: {
    modified: UpdatePurchaseElementData[];
    removed: string[];
  };
};

export const updateReservationPurchases =
  (
    { id, version }: { id: string; version: number },
    data: UpdatePurchaseFormData
  ) =>
  async (dispatch: Dispatch, getState: GetState) => {
    try {
      dispatch({ type: types.UPDATE_RESERVATION_PURCHASES_REQUEST });

      const state = getState();
      const confirmedNewInventoryItems = getConfirmedNewInventoryItems(state);
      const preAddedInventoryItems = getPreAddedInventoryItems(state);
      const propertyConfiguration = getPropertyConfiguration(state);

      if (!propertyConfiguration) {
        throw new Error('Missing property configuration');
      }

      const defaultCheckOutTime = propertyConfiguration.checkOutTime;
      const defaultCheckInTime = propertyConfiguration.checkInTime;

      const updatePayload = prepareUpdatePurchaseElementsPayload(
        [...preAddedInventoryItems, ...confirmedNewInventoryItems],
        data,
        defaultCheckOutTime,
        defaultCheckInTime
      );

      const response = await BaseApi.post<
        PreparedPurchaseElementsUpdatePayload,
        EmptyInterface
      >({
        url: buildUrl(
          BaseApi.host,
          `/${api.RESERVATIONS.PURCHASE_ELEMENTS(id)}`
        ),
        data: updatePayload,
        headers: {
          'If-Match': version,
        },
      });

      const operationId = extractIdFromLocationHeader(response.headers);

      if (!operationId) {
        throw new Error('Missing post purchase element operationId');
      }

      const status = (await repeatableCall(
        () => {
          return OperationsApi.getUpdate({
            pathParams: {
              id: operationId,
            },
          });
        },
        ({ data }) =>
          data.status?.operationStatus === OperationStatus.Completed,
        {
          intervalTime: 1000,
          repeatCount: 20,
        }
      )) as ApiOperationDto<UpdateReservationOperationDto>;

      if (
        status.data?.data?.result ===
        ReservationLongRunningOperationResult.Succeeded
      ) {
        const detailedPricing = await ReservationApi.getDetailedPricing({
          pathParams: {
            id,
          },
        });

        dispatch({
          type: types.UPDATE_RESERVATION_PURCHASES_SUCCESS,
          payload: detailedPricing.data,
        });
      } else {
        throw status;
      }
    } catch (error) {
      return dispatch({
        type: types.UPDATE_RESERVATION_PURCHASES_FAILURE,
        payload: error,
      });
    }
  };

export const updateReservationProfile = (
  { id, version }: { id: string; version: number },
  data: {
    breakdown: BreakdownUpdatePayload[];
    profileId: string;
    linkedTravelAgent?: LinkedContactProfile;
    linkedCompany?: LinkedContactProfile;
    guestContactProfiles?: ContactProfiles;
    guaranteeTypeId?: string;
  }
) => ({
  [CALL_API]: {
    data,
    endpoint: api.RESERVATIONS.UPDATE_PROFILE(id),
    method: 'POST',
    headers: {
      'If-Match': version,
    },
    types: [
      types.UPDATE_RESERVATION_PROFILE_REQUEST,
      types.UPDATE_RESERVATION_PROFILE_SUCCESS,
      types.UPDATE_RESERVATION_PROFILE_FAILURE,
    ],
  },
});

export const refetchReservation = async (
  response: AxiosResponse,
  reservationId: string,
  dispatch: Dispatch
) => {
  const isVersionCorrect = response.status !== 412;
  if (isVersionCorrect) return true;
  await dispatch(fetchReservation(reservationId));

  return false;
};

export const fetchReservation = (reservationId: string) => ({
  [CALL_API]: {
    endpoint: api.RESERVATIONS.RESERVATION(reservationId),
    types: [
      types.FETCH_RESERVATION_REQUEST,
      types.FETCH_RESERVATION_SUCCESS,
      types.FETCH_RESERVATION_FAILURE,
    ],
  },
});

export const fetchBreakdown = (reservationId: string) => ({
  [CALL_API]: {
    endpoint: api.RESERVATIONS.BREAKDOWN(reservationId),
    paginate: true,
    types: [
      types.FETCH_BREAKDOWN_REQUEST,
      types.FETCH_BREAKDOWN_SUCCESS,
      types.FETCH_BREAKDOWN_FAILURE,
    ],
  },
});

export const fetchReservationCheckIn = (
  lastName: string,
  identificationNumber?: string | number,
  departureDate?: string
) => ({
  [CALL_API]: {
    endpoint: api.RESERVATIONS.RESERVATION_CHECK_IN_AUTH,
    params: {
      lastName,
      identificationNumber,
      departureDate,
    },
    payload: {
      identificationNumber,
    },
    checkCondition: (response: AxiosResponse) => {
      const { results } = response.data;

      if (!identificationNumber && results.length > 1) {
        throw new Error(
          Configurator.checkInErrorCodes.MULTIROOM_RESERVATION_NEEDS_CONFIRMATION_NO
        );
      }

      return true;
    },
    types: [
      types.FETCH_RESERVATION_CHECK_IN_REQUEST,
      types.FETCH_RESERVATION_CHECK_IN_SUCCESS,
      types.FETCH_RESERVATION_CHECK_IN_FAILURE,
    ],
  },
});

export const fetchReservationCheckOut = (
  lastName: string,
  roomNumber: string
) => ({
  [CALL_API]: {
    endpoint: api.RESERVATIONS.RESERVATION_CHECK_OUT_AUTH,
    params: {
      lastName,
      roomNumber,
    },
    types: [
      types.FETCH_RESERVATION_CHECK_OUT_REQUEST,
      types.FETCH_RESERVATION_CHECK_OUT_SUCCESS,
      types.FETCH_RESERVATION_CHECK_OUT_FAILURE,
    ],
  },
});

export const fetchReservationGenerateKeys = (
  lastName: string,
  identificationNumber: string,
  roomNumber?: string
) => ({
  [CALL_API]: {
    endpoint: api.RESERVATIONS.RESERVATION_GENERATE_KEYS_AUTH,
    params: {
      lastName,
      identificationNumber,
      roomNumber,
    },
    types: [
      types.FETCH_RESERVATION_GENERATE_KEYS_REQUEST,
      types.FETCH_RESERVATION_GENERATE_KEYS_SUCCESS,
      types.FETCH_RESERVATION_GENERATE_KEYS_FAILURE,
    ],
  },
});

export const fetchSuggestedRoom = (
  reservationId: string,
  onlyCleanRoom = true,
  onlyVacantRooms = true
) => ({
  [CALL_API]: {
    endpoint: api.RESERVATIONS.SUGGESTED_ROOM_NUMBER(reservationId),
    params: {
      returnOnlyNonOutOfServiceRooms: true,
      returnOnlyCleanRoom: onlyCleanRoom,
      returnOnlyVacantRooms: onlyVacantRooms,
    },
    types: [
      types.FETCH_SUGGESTED_NUMBER_REQUEST,
      types.FETCH_SUGGESTED_NUMBER_SUCCESS,
      types.FETCH_SUGGESTED_NUMBER_FAILURE,
    ],
  },
});

export const fetchSharingSummary = (sharingId: string) => ({
  [CALL_API]: {
    endpoint: api.RESERVATIONS.SHARING(sharingId),
    types: [
      types.FETCH_SHARING_SUMMARY_REQUEST,
      types.FETCH_SHARING_SUMMARY_SUCCESS,
      types.FETCH_SHARING_SUMMARY_FAILURE,
    ],
  },
});

export const assignRoomOperation =
  (isReservationShared: boolean, data: object, id: string, version: number) =>
  (dispatch: Dispatch) => {
    const apiEndpoint = isReservationShared
      ? api.RESERVATIONS.ASSIGN_SHARED_ROOM
      : api.RESERVATIONS.ASSIGN_ROOM;

    return dispatch({
      [CALL_API]: {
        data,
        method: 'POST',
        headers: {
          'If-Match': version,
        },
        endpoint: apiEndpoint(id),
        types: [
          types.ASSIGN_ROOM_REQUEST,
          types.ASSIGN_ROOM_SUCCESS,
          types.ASSIGN_ROOM_FAILURE,
        ],
      },
    });
  };

export const getAssignRoomOperationStatus = (operationId: string) => ({
  [CALL_API]: {
    endpoint: api.RESERVATIONS.UPDATE_OPERATION_STATUS(operationId),
    checkCondition: (response: AxiosResponse) => {
      const { operationStatus } = response.data.status;

      return operationStatus === Configurator.operationStatuses.COMPLETED;
    },
    types: [
      types.GET_ASSIGN_ROOM_STATUS_REQUEST,
      types.GET_ASSIGN_ROOM_STATUS_SUCCESS,
      types.GET_ASSIGN_ROOM_STATUS_FAILURE,
    ],
  },
});

export const checkIn =
  (allowDirtyRoom = false, walkIn = true) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const reservationId = getReservationId(state);

    return dispatch({
      [CALL_API]: {
        data: {
          allowDirtyRoom,
          walkIn,
        },
        method: 'POST',
        checkFailureCondition: (response: AxiosResponse) =>
          refetchReservation(response, reservationId, dispatch),
        getOptions: () => ({
          headers: {
            'If-Match': getReservationVersion(getState()),
          },
        }),
        endpoint: api.RESERVATIONS.CHECK_IN(reservationId),
        types: [
          types.CHECK_IN_REQUEST,
          types.CHECK_IN_SUCCESS,
          types.CHECK_IN_FAILURE,
        ],
      },
    });
  };

export const postCheckInLetter =
  ({ definitionId }: CorrespondenceDefinitionByRole) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const email = getEmail(state);
    const id = getReservationId(state);

    return dispatch({
      [CALL_API]: {
        data: {
          documentFormat: CorrespondenceFileExtensionType.Pdf,
          definitionId,
          ...(email?.details && { emailAddresses: [email.details] }),
        },
        method: 'POST',
        endpoint: api.RESERVATIONS.POST_CHECK_IN_LETTER(id),
        types: [
          types.POST_CHECK_IN_LETTER_REQUEST,
          types.POST_CHECK_IN_LETTER_SUCCESS,
          types.POST_CHECK_IN_LETTER_FAILURE,
        ],
      },
    });
  };

export const cutKey = (reservationId: string, data: CutKeyDataModel) => {
  return {
    [CALL_API]: {
      data,
      method: 'POST',
      endpoint: api.RESERVATIONS.CUT_KEY(reservationId),
      types: [
        types.CUT_KEY_REQUEST,
        types.CUT_KEY_SUCCESS,
        types.CUT_KEY_FAILURE,
      ],
    },
  };
};

export const getCutKeyOperationStatus = (operationId: string) => ({
  [CALL_API]: {
    endpoint: api.RESERVATIONS.UPDATE_OPERATION_STATUS(operationId),
    repetitions: 30,
    interval: 1000,
    checkCondition: (response: AxiosResponse) => {
      const { operationStatus } = response.data.status;

      return operationStatus === Configurator.operationStatuses.COMPLETED;
    },
    types: [
      types.GET_CUT_KEY_STATUS_REQUEST,
      types.GET_CUT_KEY_STATUS_SUCCESS,
      types.GET_CUT_KEY_STATUS_FAILURE,
    ],
  },
});

export const duplicateKey = (reservationId: string, data: any) => ({
  [CALL_API]: {
    data,
    method: 'POST',
    endpoint: api.RESERVATIONS.DUPLICATE_KEY(reservationId),
    types: [
      types.DUPLICATE_KEY_REQUEST,
      types.DUPLICATE_KEY_SUCCESS,
      types.DUPLICATE_KEY_FAILURE,
    ],
  },
});

export const getDuplicateKeyStatus = (operationId: string) => ({
  [CALL_API]: {
    endpoint: api.RESERVATIONS.UPDATE_OPERATION_STATUS(operationId),
    checkCondition: (response: AxiosResponse) => {
      const { operationStatus } = response.data.status;

      return operationStatus === Configurator.operationStatuses.COMPLETED;
    },
    types: [
      types.GET_DUPLICATE_KEY_STATUS_REQUEST,
      types.GET_DUPLICATE_KEY_STATUS_SUCCESS,
      types.GET_DUPLICATE_KEY_STATUS_FAILURE,
    ],
  },
});

export const fetchReservationDetails = (reservationId: string) => ({
  [CALL_API]: {
    endpoint: api.RESERVATIONS.RESERVATION(reservationId),
    types: [
      types.FETCH_RESERVATION_DETAILS_REQUEST,
      types.FETCH_RESERVATION_DETAILS_SUCCESS,
      types.FETCH_RESERVATION_DETAILS_FAILURE,
    ],
  },
});

export const fetchReservationExtended =
  (reservationId: string) => async (dispatch: Dispatch) => {
    return dispatch({
      [CALL_API]: {
        endpoint: api.RESERVATIONS.RESERVATION_EXTENDED(reservationId),
        types: [
          types.FETCH_RESERVATION_EXTENDED_REQUEST,
          types.FETCH_RESERVATION_EXTENDED_SUCCESS,
          types.FETCH_RESERVATION_EXTENDED_FAILURE,
        ],
      },
    });
  };

export const fetchDetailedPricing =
  (reservationId: string) => async (dispatch: Dispatch) => {
    dispatch({ type: types.FETCH_DETAILED_PRICING_REQUEST });

    try {
      const detailedPricing = await ReservationApi.getDetailedPricing({
        pathParams: {
          id: reservationId,
        },
      });

      dispatch({
        type: types.FETCH_DETAILED_PRICING_SUCCESS,
        payload: detailedPricing.data,
      });
    } catch (error) {
      dispatch({ type: types.FETCH_DETAILED_PRICING_FAILURE, payload: error });
    }
  };

export const fetchMultiRoomSegmentDetails = (segmentId: string) => ({
  [CALL_API]: {
    endpoint: api.RESERVATIONS.MULTIROOM_SEGMENT(segmentId),
    types: [
      types.FETCH_MULTIROOM_SEGMENT_REQUEST,
      types.FETCH_MULTIROOM_SEGMENT_SUCCESS,
      types.FETCH_MULTIROOM_SEGMENT_FAILURE,
    ],
  },
});

export const fetchMultiRoomCheckinReservationList =
  (reservationIds: string[]) => async (dispatch: Dispatch) => {
    const filter = reservationIds.map((id) => `id==${id}`).join(',');
    const dueInStatusCode = `statusCode.code==${Configurator.reservationStatuses.DUE_IN}`;

    return dispatch({
      [CALL_API]: {
        endpoint: `${api.RESERVATIONS.BASE_RESERVATION}?filter=(${dueInStatusCode});(${filter})`,
        types: [
          types.FETCH_MULTIROOM_RESERVATION_LIST_REQUEST,
          types.FETCH_MULTIROOM_RESERVATION_LIST_SUCCESS,
          types.FETCH_MULTIROOM_RESERVATION_LIST_FAILURE,
        ],
      },
    });
  };

export const clearReservationErrors = () => ({
  type: types.CLEAR_RESERVATION_ERRORS,
});

export const chooseReservation = (reservationId: string) => ({
  type: types.CHOOSE_RESERVATION,
  payload: reservationId,
});

export const updatePurposeOfStay =
  (purposeOfStayId: string) =>
  async (dispatch: Dispatch, getState: GetState) => {
    const reservationId = getReservationId(getState());

    await dispatch({
      [CALL_API]: {
        data: {
          purposeOfStayId,
        },
        method: 'PATCH',
        checkFailureCondition: (response: AxiosResponse) =>
          refetchReservation(response, reservationId, dispatch),
        getOptions: () => ({
          headers: {
            'If-Match': getReservationVersion(getState()),
          },
        }),
        endpoint: api.RESERVATIONS.RESERVATION(reservationId),
        types: [
          types.UPDATE_PURPOSE_OF_STAY_REQUEST,
          types.UPDATE_PURPOSE_OF_STAY_SUCCESS,
          types.UPDATE_PURPOSE_OF_STAY_FAILURE,
        ],
      },
    });

    await dispatch(fetchReservation(reservationId));
  };

export const fullCheckIn =
  (postCheckInLetterDefinition: CorrespondenceDefinitionByRole | undefined) =>
  async (dispatch: Dispatch, getState: GetState): Promise<any> => {
    try {
      dispatch({ type: types.FULL_CHECK_IN_REQUEST });
      const state = getState();
      const photos = getDocumentPhotos(state);
      const objectUrlPdf = getCheckInSummaryPdf(state);
      const reservationId = getReservationId(state);

      if (photos) await dispatch(savePhotos());

      if (objectUrlPdf) {
        const blobPdf = await objectUrlToBlob(objectUrlPdf);
        blobPdf && (await dispatch(uploadPdf(blobPdf)));
      }

      await dispatch(checkIn());

      if (postCheckInLetterDefinition) {
        const isReservationViewsCheckedIn =
          await verifyIfReservationViewIsCheckedInStatus(reservationId);

        if (isReservationViewsCheckedIn) {
          dispatch(postCheckInLetter(postCheckInLetterDefinition));
        }
      }

      return dispatch({ type: types.FULL_CHECK_IN_SUCCESS });
    } catch (error) {
      return dispatch({ type: types.FULL_CHECK_IN_FAILURE, payload: error });
    }
  };

export const assignRoom =
  () =>
  async (dispatch: any, getState: any): Promise<any> => {
    const { NO_AVAILABLE_ROOMS, ROOM_ASSIGN_FAILED } =
      Configurator.roomErrorCodes;

    let id;
    let version;

    const {
      sharingId,
      roomTypeId,
      id: reservationId,
    } = getReservation(getState());

    await dispatch(fetchSuggestedRoom(reservationId));

    const suggestedRoom = getSuggestedRoom(getState());
    if (!suggestedRoom?.roomId) {
      throw new RoomError(NO_AVAILABLE_ROOMS);
    }

    if (sharingId) {
      await dispatch(fetchSharingSummary(sharingId));
      version = getSharingVersion(getState());
      id = sharingId;
    } else {
      await dispatch(fetchReservation(reservationId));
      version = getReservationVersion(getState());
      id = reservationId;
    }

    await dispatch(
      assignRoomOperation(
        Boolean(sharingId),
        { roomTypeId, roomId: suggestedRoom.roomId },
        id,
        version
      )
    );

    const { id: operationId } = getAssignRoomOperation(getState());
    await dispatch(getAssignRoomOperationStatus(operationId));

    const { status } = getAssignRoomOperation(getState());
    if (status !== Configurator.operationStatuses.SUCCEEDED) {
      throw new RoomError(ROOM_ASSIGN_FAILED);
    }
  };

export const resetUpdatePurchaseElementLimitStatus = () => ({
  type: types.RESET_UPDATE_PURCHASE_ELEMENT_LIMIT_STATUS,
});
