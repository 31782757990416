/* eslint-disable */
import CustomEntities from './custom-entities';
import DictionaryTypes from './dictionary-types';

const API_V0 = 'v0';
const API_V1 = 'v1';

class ConfigurationEndpoints {
  public static BASE = `configuration/${API_V0}`;
  public static CUSTOM_ENTITY = (entityType: string) =>
    `${ConfigurationEndpoints.BASE}/entities/${entityType}`;
  public static CUSTOM_VIEWS_ENTITY = (entityType: string) =>
    `${ConfigurationEndpoints.BASE}/views/entities/${entityType}`;
  public static ROOM_ATTRIBUTES = ConfigurationEndpoints.CUSTOM_ENTITY(
    CustomEntities.ROOM_ATTRIBUTE
  );
  public static CASHIERS = ConfigurationEndpoints.CUSTOM_ENTITY(
    CustomEntities.CASHIERS
  );
  public static MEMBERSHIP_LEVEL = ConfigurationEndpoints.CUSTOM_ENTITY(
    CustomEntities.MEMBERSHIP_LEVEL
  );
  public static MEMBERSHIP_TYPE = ConfigurationEndpoints.CUSTOM_ENTITY(
    CustomEntities.MEMBERSHIP_TYPE
  );
  public static PROPERTY_FLOORS = ConfigurationEndpoints.CUSTOM_ENTITY(
    CustomEntities.PROPERTY_FLOOR
  );
  public static ROOM_LOCATIONS = ConfigurationEndpoints.CUSTOM_ENTITY(
    CustomEntities.ROOM_LOCATION
  );
  public static PROFILE_TYPE = ConfigurationEndpoints.CUSTOM_ENTITY(
    CustomEntities.PROFILE_TYPE
  );
  public static WORKSTATIONS = ConfigurationEndpoints.CUSTOM_ENTITY(
    CustomEntities.WORKSTATION
  );
  public static WORKSTATION_DEVICES = ConfigurationEndpoints.CUSTOM_ENTITY(
    CustomEntities.WORKSTATION_DEVICE
  );
  public static TRANSACTION_CODES = ConfigurationEndpoints.CUSTOM_VIEWS_ENTITY(
    CustomEntities.TRANSACTION_CODES
  );
  public static FOLIO_STYLE = ConfigurationEndpoints.CUSTOM_ENTITY(
    CustomEntities.FOLIO_STYLE
  );
  public static TAX_RULES = ConfigurationEndpoints.CUSTOM_ENTITY(
    CustomEntities.TAX_RULES
  );
  public static SYSTEM_DICTIONARIES = (dictionaryType: string) =>
    `${ConfigurationEndpoints.BASE}/system-dictionaries/${dictionaryType}`;
  public static CREDIT_CARD_AUTHORIZATION_RULES =
    ConfigurationEndpoints.SYSTEM_DICTIONARIES(
      DictionaryTypes.CREDIT_CARD_AUTHORIZATION_RULE
    );
  public static ROOM_BASE = `${ConfigurationEndpoints.BASE}/entities/Room`;
  public static ROOM = (roomId: string) =>
    `${ConfigurationEndpoints.ROOM_BASE}/${roomId}`;
  public static ROOM_IMAGES = (roomId: string) =>
    `${ConfigurationEndpoints.ROOM(roomId)}/images`;
  public static ROOM_IMAGE_CONTENT = (roomId: string, imageId: string) =>
    `${ConfigurationEndpoints.ROOM_IMAGES(roomId)}/${imageId}/content`;
  public static ROOM_TYPE = (roomTypeId: string) =>
    `${ConfigurationEndpoints.BASE}/entities/RoomType/${roomTypeId}`;
  public static ROOM_TYPE_IMAGES = (roomId: string) =>
    `${ConfigurationEndpoints.ROOM_TYPE(roomId)}/images`;
  public static ROOM_TYPE_IMAGE_CONTENT = (roomId: string, imageId: string) =>
    `${ConfigurationEndpoints.ROOM_TYPE_IMAGES(roomId)}/${imageId}/content`;
  public static CURRENT_DATE = 'business-day/current-date';
}

class ReservationsEndpoints {
  public static BASE = `booking/${API_V0}`;
  public static BASE_RESERVATION = `${ReservationsEndpoints.BASE}/reservations`;
  public static SHARING = (sharingId: string) =>
    `${ReservationsEndpoints.BASE}/sharings/${sharingId}`;
  public static MULTIROOM_SEGMENT = (segmentId: string) =>
    `${ReservationsEndpoints.BASE}/segments/${segmentId}`;
  public static RESERVATION = (reservationId: string) =>
    `${ReservationsEndpoints.BASE_RESERVATION}/${reservationId}`;
  public static UPDATE_PROFILE = (reservationId: string) =>
    `${ReservationsEndpoints.RESERVATION(reservationId)}/update-profile`;
  public static CHECK_IN = (reservationId: string) =>
    `${ReservationsEndpoints.RESERVATION(reservationId)}/check-in`;
  public static POST_CHECK_IN_LETTER = (reservationId: string) =>
    `${ReservationsEndpoints.RESERVATION(
      reservationId
    )}/correspondences/post-checkin-letter`;
  public static PURCHASE_ELEMENTS = (reservationId: string) =>
    `${ReservationsEndpoints.RESERVATION(reservationId)}/purchase-elements`;
  public static RESERVATION_CHECK_IN_AUTH = `${ReservationsEndpoints.BASE_RESERVATION}/views/guest-self-checkin`;
  public static RESERVATION_CHECK_OUT_AUTH = `${ReservationsEndpoints.BASE_RESERVATION}/views/guest-self-checkout`;
  public static RESERVATION_GENERATE_KEYS_AUTH = `${ReservationsEndpoints.BASE_RESERVATION}/views/guest-self-generate-key`;
  public static BREAKDOWN = (reservationId: string) =>
    `${ReservationsEndpoints.RESERVATION(reservationId)}/breakdown`;
  public static SUGGESTED_ROOM_NUMBER = (reservationId: string) =>
    `${ReservationsEndpoints.RESERVATION(reservationId)}/suggested-room-number`;
  public static UPDATE_OPERATION_STATUS = (operationId: string) =>
    `${ReservationsEndpoints.BASE}/operations/update/${operationId}`;
  public static ASSIGN_ROOM = (reservationId: string) =>
    `${ReservationsEndpoints.RESERVATION(reservationId)}/assign-room`;
  public static ASSIGN_SHARED_ROOM = (sharingId: string) =>
    `${ReservationsEndpoints.SHARING(sharingId)}/assign-room`;
  public static CUT_KEY = (reservationId: string) =>
    `${ReservationsEndpoints.RESERVATION(reservationId)}/cut-key`;
  public static DUPLICATE_KEY = (reservationId: string) =>
    `${ReservationsEndpoints.RESERVATION(reservationId)}/duplicate-key`;
  public static ATTACHMENTS = (reservationId: string) =>
    `${ReservationsEndpoints.RESERVATION(reservationId)}/attachments`;
  public static FILES = (reservationId: string, attachmentId: string) =>
    `${ReservationsEndpoints.ATTACHMENTS(reservationId)}/${attachmentId}/files`;
  public static RESERVATION_EXTENDED = (reservationId: string) =>
    `${ReservationsEndpoints.RESERVATION(
      reservationId
    )}?extend=Breakdown,PurchaseElements`;
}

class ProfilesEndpoints {
  public static BASE = `profiles/${API_V0}`;
  public static COMPANY_BASE = `${ProfilesEndpoints.BASE}/company`;
  public static INDIVIDUAL_BASE = `${ProfilesEndpoints.BASE}/individual`;
  public static TRAVEL_AGENT_BASE = `${ProfilesEndpoints.BASE}/travel-agent`;
  public static INDIVIDUAL_MATCHING = `${ProfilesEndpoints.INDIVIDUAL_BASE}/views/match`;
  public static PROFILES_VIEWS = (filter: string) =>
    `${ProfilesEndpoints.BASE}/views/dashboard?filter=(${filter})`;
  public static PROFILE = (profileId: string) =>
    `${ProfilesEndpoints.INDIVIDUAL_BASE}/${profileId}`;
  public static ADD_CONSENTS = (profileId: string) =>
    `${ProfilesEndpoints.PROFILE(profileId)}/grant-consents`;
  public static REVOKE_CONSENTS = (profileId: string) =>
    `${ProfilesEndpoints.PROFILE(profileId)}/revoke-consents`;
  public static ATTACHMENTS = (profileId: string) =>
    `${ProfilesEndpoints.PROFILE(profileId)}/attachments`;
  public static FILES = (profileId: string, attachmentId: string) =>
    `${ProfilesEndpoints.ATTACHMENTS(profileId)}/${attachmentId}/files`;
  public static COMPANY = (companyId: string) =>
    `${ProfilesEndpoints.COMPANY_BASE}/${companyId}`;
  public static LINK_COMPANY = (companyId: string) =>
    `${ProfilesEndpoints.COMPANY(companyId)}/link-profile`;
  public static TRAVEL_AGENT = (travelAgentId: string) =>
    `${ProfilesEndpoints.TRAVEL_AGENT_BASE}/${travelAgentId}`;
}

class HousekeepingEndpoints {
  public static BASE = `housekeeping/${API_V0}`;
  public static AVAILABLE_ROOMS = `${HousekeepingEndpoints.BASE}/views/available-rooms`;
}

class AvailabilityEndpoints {
  public static BASE = `availability/${API_V0}`;
  public static PURCHASE_ELEMENTS = `${AvailabilityEndpoints.BASE}/quotes/purchase-elements`;
}

class CashieringEndpoints {
  public static BASE = `cashiering/${API_V0}`;
  public static CASHIERS = `${CashieringEndpoints.BASE}/cashiers`;
  public static ACCOUNTS = `${CashieringEndpoints.BASE}/accounts`;
  public static ACCOUNT = (accountId: string) =>
    `${CashieringEndpoints.ACCOUNTS}/${accountId}`;
  public static FOLIOS = (accountId: string) =>
    `${CashieringEndpoints.ACCOUNTS}/${accountId}/folios`;
  public static FOLIO = (accountId: string, folioId: string) =>
    `${CashieringEndpoints.FOLIOS(accountId)}/${folioId}`;
  public static CHANGE_FOLIO_PROFILE = (accountId: string, folioId: string) =>
    `${CashieringEndpoints.FOLIO(accountId, folioId)}/change-profile`;
  public static PAYMENT_METHODS = (accountId: string) =>
    `${CashieringEndpoints.ACCOUNTS}/${accountId}/payment-methods`;
  public static PAYMENT_METHOD = (accountId: string, methodId: string) =>
    `${CashieringEndpoints.PAYMENT_METHODS(accountId)}/${methodId}`;
  public static LINK_PAYMENT_METHOD = (accountId: string, folioId: string) =>
    `${CashieringEndpoints.FOLIOS(accountId)}/${folioId}/link-payment-method`;
  public static BILLING_INSTRUCTIONS = (accountId: string) =>
    `${CashieringEndpoints.ACCOUNTS}/${accountId}/billing-instructions`;
  public static APPLY_BILLING_INSTRUCTIONS = (accountId: string) =>
    `${CashieringEndpoints.ACCOUNTS}/${accountId}/apply-billing-instructions`;
  public static APPLY_BILLING_INSTRUCTIONS_STATUS = (operationId: string) =>
    `${CashieringEndpoints.BASE}/operations/apply-billing-instructions/${operationId}`;
  public static TRANSACTIONS = (accountId: string, folioId: string) =>
    `${CashieringEndpoints.FOLIO(accountId, folioId)}/transactions`;
  public static TRANSFER_TRANSACTIONS = `${CashieringEndpoints.BASE}/transfer-transactions`;
  public static CHECK_OUT = (accountId: string) =>
    `${CashieringEndpoints.ACCOUNTS}/${accountId}/check-out`;
  public static CHECK_OUT_STATUS = (operationId: string) =>
    `${CashieringEndpoints.BASE}/operations/check-out/${operationId}`;
  public static CHECK_OUT_FOLIO = (accountId: string, folioId: string) =>
    `${CashieringEndpoints.ACCOUNTS}/${accountId}/folios/${folioId}/check-out`;
  public static CHECK_OUT_FOLIO_STATUS = (operationId: string) =>
    `${CashieringEndpoints.BASE}/operations/folio-check-out/${operationId}`;
  public static CORRESPONDENCES = (accountId: string, folioId: string) =>
    `${CashieringEndpoints.ACCOUNTS}/${accountId}/folios/${folioId}/correspondences`;
  public static INVOICE_FINAL = (accountId: string, folioId: string) =>
    `${CashieringEndpoints.CORRESPONDENCES(accountId, folioId)}/invoice/final`;
  public static INVOICE_EMAIL = (accountId: string, folioId: string) =>
    `${CashieringEndpoints.CORRESPONDENCES(accountId, folioId)}/invoice/email`;
  public static ADD_BATCH = (accountId: string, folioId: string) =>
    `${CashieringEndpoints.TRANSACTIONS(accountId, folioId)}/add-batch`;
  public static CREDIT_CARD_PROCESSING_STATUS = (operationId: string) =>
    `${CashieringEndpoints.BASE}/operations/credit-card-processing/${operationId}`;
  public static AUTHORIZATION = (authorizationId: string) =>
    `${CashieringEndpoints.BASE}/credit-card/authorization/${authorizationId}`;
  public static VOID_AUTHORIZATION = (authorizationId: string) =>
    `${CashieringEndpoints.AUTHORIZATION(authorizationId)}/void`;
  public static RESERVATION_OUTSTANDING_DEPOSIT = (reservationId: string) =>
    `${CashieringEndpoints.BASE}/reservation-deposit-requests/${reservationId}/outstanding-amount`;
  public static KIOSK_AUTHORIZATION_AMOUNT = (accountId: string) =>
    `${CashieringEndpoints.ACCOUNTS}/${accountId}/kiosk-authorization-amount-calculation`;
  public static RESERVATION_CHARGES = (accountId: string) =>
    `${CashieringEndpoints.ACCOUNTS}/${accountId}/reservation-charges`;
  public static CHARGE_RESERVATIONS = `${CashieringEndpoints.BASE}/charge-reservations`;
  public static CHARGE_RESERVATIONS_STATUS = (operationId: string) =>
    `${CashieringEndpoints.BASE}/operations/post-reservation-charges/${operationId}`;
  public static AVAILABLE_FOLIO_STYLES = (
    accountId: string,
    folioWindowId: string
  ) =>
    `${CashieringEndpoints.ACCOUNT(
      accountId
    )}/folios/${folioWindowId}/available-folio-styles`;
}

class AggregatorEndpoints {
  public static BASE = `aggregator/${API_V0}`;
  public static RESERVATION = (reservationId: string) =>
    `${AggregatorEndpoints.BASE}/reservations/${reservationId}`;
}

class PropertyManagementEndpoints {
  public static BASE = `business-day/${API_V0}`;
  public static LOCAL_DATE_TIME = `${PropertyManagementEndpoints.BASE}/property-local-date-time`;
  public static CURRENT_DATE = `${PropertyManagementEndpoints.BASE}/current-date`;
}

class RateManagerEndpoints {
  public static BASE = `rate-manager/${API_V0}`;
  public static ENTITY = (entityType: string) =>
    `${RateManagerEndpoints.BASE}/entities/${entityType}`;
  public static PURCHASE_ELEMENTS = `${RateManagerEndpoints.ENTITY(
    CustomEntities.PURCHASE_ELEMENTS
  )}`;
  public static RATE_PLANS = `${RateManagerEndpoints.ENTITY(
    CustomEntities.RATE_PLANS
  )}`;
  public static AGE_BUCKETS = `${RateManagerEndpoints.ENTITY(
    CustomEntities.AGE_BUCKETS
  )}`;
  public static IMAGES = (id: string) =>
    `${RateManagerEndpoints.PURCHASE_ELEMENTS}/${id}/images`;
  public static IMAGE_CONTENT = (id: string, imageId: string) =>
    `${RateManagerEndpoints.PURCHASE_ELEMENTS}/${id}/images/${imageId}/content`;
}

class PaymentInterfaceEndpoints {
  public static BASE = `integration-payment-interface/${API_V0}`;
  public static INTERFACE = (id: string) =>
    `${PaymentInterfaceEndpoints.ADAPTER_CONFIGURATIONS}/ShijiSPAPayment/${id}`;
  public static ADAPTER_CONFIGURATIONS = `${PaymentInterfaceEndpoints.BASE}/adapter-configurations`;
  public static ADAPTER_CONFIGURATION = (type: string, id: string) =>
    `${PaymentInterfaceEndpoints.ADAPTER_CONFIGURATIONS}/${type}/${id}`;
}

export default {
  CONFIGURATION: ConfigurationEndpoints,
  RESERVATIONS: ReservationsEndpoints,
  PROFILES: ProfilesEndpoints,
  HOUSEKEEPING: HousekeepingEndpoints,
  AVAILABILITY: AvailabilityEndpoints,
  CASHIERING: CashieringEndpoints,
  AGGREGATOR: AggregatorEndpoints,
  PROPERTY_MANAGEMENT: PropertyManagementEndpoints,
  RATE_MANAGER: RateManagerEndpoints,
  PAYMENT_INTERFACE: PaymentInterfaceEndpoints,
};
