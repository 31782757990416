import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Big from 'big.js';
import { Modal } from 'components';
import { ids } from 'config';
import { compose } from 'redux';
import { getFormValues } from 'redux-form';
import {
  getAllFolioCharges,
  getCurrentMinibarCharges,
  getMinibarPendingCharges,
} from 'store/cashiering/selectors';
import {
  getDepartureHour,
  getFirstNightRatePlan,
  getLastNightRatePlan,
  isReservationDayUse,
} from 'store/reservation/selectors';
import { getGuestDetails, getReservationDetails } from 'store/selectors';
import { toggleDayUseModal } from 'store/ui/actions';
import { isReservationHeaderExtended } from 'store/ui/selectors';
import { RatePlan } from 'types/Api/RateManager';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';
import { Path } from 'utils/Router';

import { IconTypes } from '@ac/kiosk-components';

import { RouteComponentProps, withRouter } from '@LEGACY/utils/withRouter';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import Grid, { GridProps } from '@material-ui/core/Grid';
import { WithStyles, withStyles } from '@material-ui/styles';

import AdditionalInfo from '../AdditionalInfo';
import SegmentInfo from '../SegmentInfo';
import SegmentTitle from '../SegmentTitle';

import DayUseInfo from './DayUseInfo';
import ExpandIcon from './ExpandIcon';
import styles from './ReservationHeader.style';
import TotalPrice from './TotalPrice';

const EXPANSION_PANEL_SPACING = 8;

interface PassedProps {}

interface ReservationHeaderProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  isExpanded: boolean;
  profile: {
    name: string;
    vipCode: string;
    email: string;
    mobile: string;
    address: string;
    membership: string;
  };
  reservationDetails: {
    bookingPeriod: string;
    nights: number;
    adults: number;
    children: number;
    totalPrice: number;
    roomName: string;
    roomNumber: string;
  };
  minibarPrice: number;
  orderedItems?: any;
  grossBalanceFromAllFolios: number;
  minibarPendingCharges: number;
  isReservationDayUse: boolean;
  firstNightRatePlan: RatePlan;
  lastNightRatePlan: RatePlan;
  toggleDayUseModal: typeof toggleDayUseModal;
  departureHour: string;
}

interface ReservationHeaderState {
  errorModalOpen: boolean;
}

class ReservationHeader extends PureComponent<
  ReservationHeaderProps,
  ReservationHeaderState
> {
  public static defaultProps = {
    profile: {
      name: '',
      vipCode: '',
      email: '',
      mobile: '',
      address: '',
      membership: '',
    },
    reservationDetails: {
      bookingPeriod: '',
      nights: 0,
      adults: 0,
      children: 0,
      totalPrice: 0,
      roomName: '',
      roomNumber: '',
    },
  };

  public state = {
    errorModalOpen: false,
  };

  public componentDidMount() {
    this.checkPriceType();
  }

  public render() {
    const {
      classes,
      t,
      isExpanded,
      toggleDayUseModal,
      profile: { name, email, mobile, address, membership },
      reservationDetails: { roomName, roomNumber, bookingPeriod },
      departureHour,
      isReservationDayUse,
    } = this.props;
    const columnSettings: GridProps = {
      xs: 12,
      sm: this.isExpandVisible ? 6 : 4,
    };
    const isCheckOut = Router.currentPath === Path.checkOut;
    const { SHOW_RATE_PLAN } = Configurator.switchCodes;
    const showRatePlan = Configurator.getSwitch(SHOW_RATE_PLAN);
    const { errorModalOpen } = this.state;
    const { CHECK_OUT_NOT_POSSIBLE } = Configurator.getTranslationCodes();
    const defaultError = Configurator.getTranslation(CHECK_OUT_NOT_POSSIBLE);

    return (
      <Accordion
        expanded={isExpanded && this.isExpandVisible}
        classes={{
          root: classes.expansionPanel,
          expanded: classes.panelExpanded,
        }}
      >
        <Grid>
          <Modal
            isOpen={errorModalOpen}
            type="error"
            defaultError={defaultError}
            onClick={this.onModalClick}
          />
          <AccordionSummary
            classes={{
              root: classes.expansionSummary,
              content: classes.content,
            }}
          >
            <Grid container className={classes.headerSegment}>
              <Grid item {...columnSettings}>
                {isExpanded && (
                  <SegmentTitle
                    title={t('GUEST_DETAILS')}
                    className={classes.expandedColumnTitle}
                  />
                )}
                <SegmentInfo
                  title={name || t('NOT_PROVIDED')}
                  subtitle={membership}
                  classNames={{
                    titleClass: classes.segmentInfoTitle,
                    segmentInfoWrapper: isExpanded
                      ? classes.segmentInfoWrapper
                      : '',
                    subtitleClass: this.isExpandVisible
                      ? classes.segmentInfoSubtitleExpandable
                      : classes.segmentInfoSubtitle,
                  }}
                />
              </Grid>
              <Grid item {...columnSettings}>
                {isExpanded && (
                  <SegmentTitle
                    title={t('RESERVATION_DETAILS')}
                    className={classes.expandedColumnTitle}
                  />
                )}
                {this.isExpandVisible ? (
                  <SegmentInfo
                    title={bookingPeriod}
                    subtitle={
                      isReservationDayUse && !isCheckOut ? (
                        <DayUseInfo
                          hour={departureHour}
                          onClick={toggleDayUseModal}
                        />
                      ) : undefined
                    }
                    subtitleList={this.getBookingInfo()}
                    classNames={{
                      titleClass: classes.segmentInfoTitle,
                      segmentInfoWrapper: isExpanded
                        ? classes.segmentInfoWrapper
                        : '',
                      subtitleListClass: this.isExpandVisible
                        ? classes.segmentInfoSubtitleExpandable
                        : classes.segmentInfoSubtitle,
                    }}
                  />
                ) : (
                  <>
                    <SegmentInfo
                      title={bookingPeriod}
                      classNames={{ titleClass: classes.titleClass }}
                    />
                    {isReservationDayUse && (
                      <SegmentInfo
                        subtitle={
                          <DayUseInfo
                            hour={departureHour}
                            onClick={toggleDayUseModal}
                          />
                        }
                      />
                    )}
                    <SegmentInfo
                      subtitleList={this.getBookingInfo()}
                      classNames={{ subtitleListClass: classes.bookingInfo }}
                    />
                    {showRatePlan && (
                      <SegmentInfo
                        subtitle={this.getRatePlan()}
                        classNames={{ subtitleClass: classes.ratePlan }}
                      />
                    )}
                  </>
                )}
              </Grid>
              {!this.isExpandVisible && (
                <Grid item {...columnSettings}>
                  <TotalPrice isExpandVisible={this.isExpandVisible} />
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <div className={classes.expandButton}>
            {this.isExpandVisible && <ExpandIcon isExpanded={isExpanded} />}
          </div>
        </Grid>
        <AccordionDetails classes={{ root: classes.expansionDetails }}>
          <Grid
            container
            className={classes.headerSegment}
            spacing={EXPANSION_PANEL_SPACING}
          >
            <Grid
              item
              xs={12}
              sm={6}
              className={
                this.isExpandVisible && !membership
                  ? classes.contactInfoWithoutMembership
                  : classes.contactInfo
              }
            >
              <AdditionalInfo
                classNames={{ wrapper: classes.emailAddressInfo }}
                info={email || t('NOT_PROVIDED')}
                icon={IconTypes.email}
              />
              <AdditionalInfo
                info={mobile || t('NOT_PROVIDED')}
                icon={IconTypes.mobile}
              />
              <AdditionalInfo
                info={address || t('NOT_PROVIDED')}
                icon={IconTypes.address}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className={classes.segmentInfoWrapper}>
                <SegmentInfo
                  title={t('ROOM', { room: roomNumber })}
                  subtitle={roomName || t('NOT_PROVIDED')}
                  classNames={{
                    titleClass: classes.segmentInfoTitle,
                    subtitleClass: this.isExpandVisible
                      ? classes.segmentInfoSubtitleExpandable
                      : classes.segmentInfoSubtitle,
                  }}
                />
                {showRatePlan && (
                  <SegmentInfo
                    subtitle={this.getRatePlan()}
                    classNames={{ subtitleListClass: classes.bookingInfo }}
                  />
                )}
              </div>
              <TotalPrice isExpandVisible={this.isExpandVisible} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }

  private get isExpandVisible() {
    return Configurator.getSwitch(
      Configurator.switchCodes.SHOW_EXPAND_FOR_RESERVATION_HEADER,
      false
    );
  }

  private getBookingInfo = (): string[] => {
    const {
      t,
      reservationDetails: { nights, adults, children },
    } = this.props;

    return [
      t('NIGHT', { count: nights }),
      t('ADULT', { count: adults }),
      t('CHILD', { count: children }),
    ];
  };

  private getRatePlan = () => {
    const { firstNightRatePlan, lastNightRatePlan } = this.props;
    const isCheckIn = Router.currentPath === Path.checkIn;
    const firstRatePlan = firstNightRatePlan
      ? firstNightRatePlan.description[0].content
      : '';
    const lastRatePlan = lastNightRatePlan
      ? lastNightRatePlan.description[0].content
      : '';

    return isCheckIn ? firstRatePlan : lastRatePlan;
  };

  private checkPriceType = () => {
    const {
      minibarPrice,
      grossBalanceFromAllFolios,
      minibarPendingCharges,
      reservationDetails: { totalPrice },
    } = this.props;
    const isCheckOut = Router.currentPath === Path.checkOut;
    const totalPriceAmount = isCheckOut
      ? new Big(grossBalanceFromAllFolios)
          .plus(minibarPrice)
          .minus(minibarPendingCharges)
          .toNumber()
      : totalPrice;
    if (typeof totalPriceAmount !== 'number')
      this.setState({ errorModalOpen: true });
  };

  private onModalClick = () => {
    const { history } = this.props;
    this.setState({ errorModalOpen: false });

    return history.push(Router.paths.WELCOME);
  };
}

const mapStateToProps = (state: Store) => ({
  minibarPendingCharges: getMinibarPendingCharges(state),
  minibarPrice: getCurrentMinibarCharges(state),
  isExpanded: isReservationHeaderExtended(state),
  profile: getGuestDetails(state),
  reservationDetails: getReservationDetails(state),
  orderedItems: getFormValues(ids.MINIBAR_FORM)(state),
  grossBalanceFromAllFolios: getAllFolioCharges(state),
  firstNightRatePlan: getFirstNightRatePlan(state),
  lastNightRatePlan: getLastNightRatePlan(state),
  isReservationDayUse: isReservationDayUse(state),
  departureHour: getDepartureHour(state),
});

const mapDispatchToProps = {
  toggleDayUseModal,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(ReservationHeader) as (props: PassedProps) => JSX.Element;
